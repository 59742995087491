@import "../../scss/shared";

$ghost_gray: #d1d1d1;
$dark_gray: #707070;

.addDiscountCodeForm {
    width: 600px;
    padding: 20px;

    div {
        margin-bottom: 10px;
    }

    button[type=submit] {
        @extend .btnPrimary;

        margin: auto;
        height: 30px;
    }

    button[type=button] {
        @extend .btnSecondary;
    }
}

.inputContainer {
    display: flex;
    flex-direction: column;

    label {
        @extend .inputLabel;
    }
}

.inputLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
        width: 50%;
    }
    input {
        @extend .roundInput;
        margin-bottom: 5px;
    }
    select {
        @extend .roundInput;
        margin-bottom: 5px;
    }
    input, select {
        width: 50%;
    }
}

.checkboxContainer {
    input {
        margin-right: 10px;
    }
}

.affiliatesInputs {
    padding: 20px;
    border: 1px solid $ghost_gray;
    border-radius: 10px;
}

.hiddenLabelRightCentered {
    @extend .inputLabel;
    display: flex;
    flex-direction: row;

    span {
        &:nth-of-type(1) {
            visibility: hidden;
        }

        &:nth-of-type(2) {
            display: flex;
            align-items: center;
            justify-content: center;
            justify-items: center;
        }
    }
}

.discountAmount {
    @extend .inputContainer;

    label {
        select {
            width: 100%;
        }

        input {
            width: 90%;
        }
    }
}

.btn {
    cursor: pointer;
    font-size: 0.9em;
    display: block;
    padding: 0 2em;
    border-radius: 10px;
    transition: 400ms all;
    font-weight: 550;
}

.btnPrimary {
    @extend .btn;
  
    color: #ffffff;
    background-color: $primary_blue;
    border: 0;
  
    &:hover {
      background-color: #005da0;
    }
  
    &:disabled {
      background-color: #007bd033;
    }
}

.btnSecondary {
    @extend .btn;
  
    color: $dark_gray;
    border: 1px solid $ghost_gray;
  
    &:hover {
      border-color: $primary_blue;
      color: $primary_blue;
    }
  
    &:disabled {
      color: $ghost_gray;
      border-color: $ghost_gray;
    }
}

.roundInput {
    box-sizing: border-box;
    display: inline-block;
    font-size: 0.9em;
    min-width: 9vw;
    height: 2em;
    border-radius: 10vw;
    border: 1px solid #d9d9d9;
    padding: 0 25px;
    color: black;
  
    &::placeholder {
      color: #d9d9d9;
    }
}

.successModal {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
        text-align: center;
    }

    .successReturnBtn {
        @extend .btnPrimary;

        width: 100px;
        height: 30px;
        margin: auto;
        margin-top: 20px;
    }

    div {
        display: flex;
        flex-direction: column;

        div {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            span {
                a {
                    color: $primary_blue;
                }
            }
        }
    }
}