@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400");
/* Media Queries */
/* Media Queries */
.sidebar {
  width: 20vw;
  align-items: center;
  background: linear-gradient(315deg, #76C3F8 0%, #007BD0 100%);
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0; }
  @media (max-width: 575px) {
    .sidebar {
      width: 100vw;
      height: 10vh; } }
  @media (min-width: 1500px) {
    .sidebar {
      width: 300px; } }
  .sidebar ul {
    list-style-type: none;
    display: block;
    width: 12vw; }
    @media (min-width: 576px) and (max-width: 767px) {
      .sidebar ul {
        margin-left: -15px; } }
    @media (min-width: 1500px) {
      .sidebar ul {
        width: 180px; } }
    @media (max-width: 575px) {
      .sidebar ul {
        display: inline-block;
        float: right;
        margin-right: 5vw;
        margin-top: 4vh;
        width: 30vw;
        white-space: nowrap; } }
    .sidebar ul li {
      float: left; }
      @media (min-width: 576px) {
        .sidebar ul li {
          margin-top: 3vh;
          display: inline; } }
      .sidebar ul li img {
        float: left;
        padding-right: 10px;
        padding-top: 2px;
        position: relative;
        width: 1.4vw; }
        @media (max-width: 575px) {
          .sidebar ul li img {
            width: 2.5vh;
            padding-right: 0; } }
        @media (min-width: 1500px) {
          .sidebar ul li img {
            width: 18px; } }

a, .menu-button {
  text-decoration: none;
  text-align: left;
  display: block;
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 1.2vw;
  float: left;
  background: none;
  border: none;
  cursor: pointer; }
  @media (min-width: 1500px) {
    a, .menu-button {
      font-size: 18px; } }
  a span, .menu-button span {
    white-space: nowrap; }
    @media (min-width: 576px) and (max-width: 767px) {
      a span, .menu-button span {
        font-size: 2vw; } }
    @media (min-width: 768px) and (max-width: 991px) {
      a span, .menu-button span {
        font-size: 1.6vw; } }
    @media (max-width: 575px) {
      a span, .menu-button span {
        display: none; } }
  @media (max-width: 575px) {
    a, .menu-button {
      display: inline-block;
      float: right; } }

.logo-img {
  width: 12vw;
  margin-top: 20px;
  float: left;
  margin-left: 15px;
  margin-bottom: 5vh; }
  @media (max-width: 575px) {
    .logo-img {
      float: left;
      width: 120px;
      align-items: center;
      margin-top: 1.5vh; } }
  @media (min-width: 576px) and (max-width: 767px) {
    .logo-img {
      width: 17vw;
      margin-left: 10px; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .logo-img {
      width: 15vw; } }
  @media (min-width: 1500px) {
    .logo-img {
      width: 180px; } }
