@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400");
/* Media Queries */
/* Media Queries */
.admin-sidebar {
  width: 20vw;
  align-items: center;
  background: linear-gradient(315deg, #76C3F8 0%, #007BD0 100%);
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0; }
  @media (max-width: 575px) {
    .admin-sidebar {
      width: 100%;
      height: 10vh; } }
  @media (min-width: 1500px) {
    .admin-sidebar {
      width: 300px; } }
  .admin-sidebar ul {
    list-style-type: none;
    display: block;
    width: 10vw; }
    @media (min-width: 576px) and (max-width: 767px) {
      .admin-sidebar ul {
        margin-left: -15px; } }
    @media (min-width: 1500px) {
      .admin-sidebar ul {
        width: 100px; } }
    @media (max-width: 575px) {
      .admin-sidebar ul {
        display: inline-block;
        float: right;
        margin-right: 5vw;
        margin-top: 4vh;
        width: 80vw;
        white-space: nowrap; } }
    .admin-sidebar ul li {
      float: left;
      width: 8vw; }
      @media (min-width: 576px) {
        .admin-sidebar ul li {
          margin-top: 3vh;
          display: inline; } }
      @media (max-width: 575px) {
        .admin-sidebar ul li {
          margin-right: 1vh;
          float: left; } }
      .admin-sidebar ul li img {
        float: left;
        padding-right: 10px;
        padding-top: 2px;
        position: relative;
        width: 1.4vw; }
        @media (max-width: 575px) {
          .admin-sidebar ul li img {
            width: 2.5vh;
            padding-right: 0; } }
        @media (min-width: 1500px) {
          .admin-sidebar ul li img {
            width: 18px; } }
    @media (max-width: 575px) {
      .admin-sidebar ul .search-results-container {
        text-align: center;
        background-color: #007BD0;
        opacity: 1;
        position: fixed;
        z-index: 10;
        top: 20vh;
        bottom: 5vh;
        left: 15vw;
        right: 15vw;
        border-radius: 25px; } }

.menu-li {
  margin-right: 0;
  width: 0; }

@media (min-width: 576px) {
  .exit-mob-search {
    display: none !important; } }

@media (max-width: 575px) {
  .exit-mob-search {
    position: fixed;
    z-index: 12;
    left: 75vw;
    top: 14vh;
    width: 7vw;
    height: 7vw;
    border: none;
    border-radius: 1vw; } }

@media (min-width: 576px) {
  .result-li {
    margin-left: -2.5vw;
    font-weight: 600; } }

.searchbar {
  border: none;
  width: 12vw;
  padding: 0.5vw;
  margin-bottom: 2.5vh; }
  @media (min-width: 1500px) {
    .searchbar {
      width: 180px; } }
  @media (max-width: 575px) {
    .searchbar {
      display: inline-block;
      position: fixed;
      z-index: 11;
      top: 21vh;
      left: 20vw;
      width: 60vw;
      height: 5vh; } }

@media (max-width: 575px) {
  .search-results-background {
    background-color: black;
    opacity: 0.5;
    position: fixed;
    top: 10vh;
    bottom: 0;
    left: 0;
    right: 0; } }

@media (max-width: 575px) {
  .results-list {
    position: fixed;
    top: 25vh;
    left: 5vw;
    bottom: 8vh;
    overflow-y: scroll; }
    .results-list li {
      height: 3.5vh;
      width: 30vw;
      margin: 5px 5px 5px 5px; }
      .results-list li a {
        font-size: 3vw;
        font-weight: 600;
        padding: 10px 10px 10px 10px;
        margin-right: 20vw; } }

a, .menu-button {
  text-decoration: none;
  padding: 0 0 1px 0;
  text-align: left;
  display: block;
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 1.2vw;
  float: left;
  background: none;
  border: none;
  cursor: pointer; }
  @media (min-width: 1500px) {
    a, .menu-button {
      font-size: 18px; } }
  a span, .menu-button span {
    white-space: nowrap; }
    @media (min-width: 576px) and (max-width: 767px) {
      a span, .menu-button span {
        font-size: 2vw; } }
    @media (min-width: 768px) and (max-width: 991px) {
      a span, .menu-button span {
        font-size: 1.6vw; } }
    @media (max-width: 575px) {
      a span, .menu-button span {
        display: none; } }
  @media (max-width: 575px) {
    a, .menu-button {
      display: inline-block;
      float: right; } }

@media (max-width: 575px) {
  .first-li {
    margin: 10px 10px 10px 10px;
    position: fixed;
    top: 2.5vh;
    right: 22vw;
    z-index: 1; } }

@media (max-width: 575px) {
  .second-li {
    margin: 10px 10px 10px 10px;
    position: fixed;
    top: 2.5vh;
    right: 13vw; } }

@media (max-width: 575px) {
  .third-li {
    margin: 10px 10px 10px 10px;
    position: fixed;
    top: 2.5vh;
    right: 2vw; } }

.menu-label {
  display: block; }
  @media (max-width: 575px) {
    .menu-label {
      display: none; } }

.logo-img {
  width: 12vw;
  margin-top: 20px;
  float: left;
  margin-left: 15px;
  margin-bottom: 1vh; }
  @media (max-width: 575px) {
    .logo-img {
      float: left;
      width: 120px;
      align-items: center;
      margin-top: 1.5vh; } }
  @media (min-width: 576px) and (max-width: 767px) {
    .logo-img {
      width: 17vw;
      margin-left: 10px; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .logo-img {
      width: 15vw; } }
  @media (min-width: 1500px) {
    .logo-img {
      width: 180px; } }

.logout-button-li {
  color: white;
  cursor: pointer; }
