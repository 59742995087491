@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400");
/* Media Queries */
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400");
/* Media Queries */
.infosection {
  overflow-y: auto;
  overflow-x: hidden;
  width: 80vw;
  position: fixed;
  z-index: -1;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  text-align: left; }
  .infosection .headings {
    width: 80vw; }
    @media (max-width: 575px) {
      .infosection .headings {
        margin-right: auto;
        margin-left: 10px;
        width: 300px; } }
  @media (max-width: 575px) {
    .infosection {
      margin-left: auto;
      margin-right: auto;
      position: fixed;
      bottom: 0;
      top: 10vh;
      width: 100vw; } }
  .infosection h2, .infosection h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    font-weight: bolder;
    color: #007BD0;
    padding-left: 20px;
    padding-right: 20px;
    letter-spacing: 3px; }
    @media (max-width: 575px) {
      .infosection h2, .infosection h1 {
        font-size: 6vw;
        padding-left: 0;
        margin-top: 20px; } }
  .infosection h1 {
    color: #d9d9d9;
    font-size: 15px;
    display: inline-block;
    cursor: pointer; }
    @media (max-width: 575px) {
      .infosection h1 {
        font-size: 3.25vw;
        margin-top: 0;
        margin-bottom: 0; } }

.tooltip-invis {
  opacity: 0;
  padding-left: 0;
  transition: opacity 0.25s linear; }

.tooltip-vis {
  opacity: 1;
  padding-left: 0; }
