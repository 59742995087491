/* Media Queries */
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400");
/* Media Queries */
.coupon-card, .earnings-card {
  float: left;
  margin: 10px 30px 10px 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #4a4a4a;
  width: 380px;
  box-shadow: 5px 15px 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 25px; }
  @media (max-width: 575px) {
    .coupon-card, .earnings-card {
      margin-right: auto;
      margin-left: 20px;
      width: 90%; } }

.code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px; }
  .code-container .code-text {
    margin-bottom: 10px; }

.coupon-code {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 2.6px;
  color: #007BD0; }

.earnings-card {
  clear: left; }

h3 {
  margin-block-start: 10px;
  margin-block-end: 1px;
  overflow-wrap: break-spaces;
  text-align: left;
  float: left;
  padding-left: 20px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 15px;
  color: #4a4a4a; }
  @media (max-width: 575px) {
    h3 {
      font-size: 11px; } }

h4 {
  margin-top: -55px;
  margin-bottom: -10px;
  text-align: right;
  float: right;
  padding-right: 30px;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 2.6px;
  color: #007BD0; }
  @media (max-width: 992px) {
    h4 {
      font-size: 24px; } }
  @media (max-width: 768px) {
    h4 {
      font-size: 22px; } }
  @media (max-width: 575px) {
    h4 {
      font-size: 20px; } }

hr {
  border-top: dashed 1px;
  border-bottom: none;
  clear: both;
  margin-left: 30px;
  margin-right: 30px;
  border-color: #e9e9e9;
  margin-block-end: 0; }

.commission-text {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center; }

h5 {
  margin-top: 15px;
  margin-right: 15px;
  margin-left: 15px;
  text-align: center;
  background-color: #007BD0;
  padding: 2px 5px;
  max-width: 440px;
  color: #ffffff;
  font-size: 15px;
  border-radius: 25px; }
  @media (max-width: 575px) {
    h5 {
      font-size: 12px;
      margin-top: 55px; } }

h6 {
  margin-top: 20px;
  margin-bottom: 0;
  text-align: right;
  float: left;
  display: inline-block;
  padding-right: 10px;
  padding-left: 5px;
  font-size: 32px;
  font-weight: bold;
  color: #007BD0; }
  @media (max-width: 575px) {
    h6 {
      font-size: 30px; } }

p {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 10px 15px;
  margin: 0;
  color: #c1c1c1; }
  @media (max-width: 575px) {
    p {
      font-size: 10px;
      padding: 5px 10px; } }
