@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Media Queries */
/* Media Queries */
.infosection {
  overflow-y: auto;
  overflow-x: hidden;
  width: 80vw;
  position: fixed;
  z-index: -1;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  text-align: left; }
  .infosection .headings {
    width: 80vw; }
    @media (max-width: 575px) {
      .infosection .headings {
        margin-right: auto;
        margin-left: 10px;
        width: 300px; } }
  @media (max-width: 575px) {
    .infosection {
      margin-left: auto;
      margin-right: auto;
      position: fixed;
      bottom: 0;
      top: 10vh;
      width: 100vw; } }
  .infosection h2, .infosection h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    font-weight: bolder;
    color: #007BD0;
    padding-left: 20px;
    padding-right: 20px;
    letter-spacing: 3px; }
    @media (max-width: 575px) {
      .infosection h2, .infosection h1 {
        font-size: 6vw;
        padding-left: 0;
        margin-top: 20px; } }
  .infosection h1 {
    color: #d9d9d9;
    font-size: 15px;
    display: inline-block;
    cursor: pointer; }
    @media (max-width: 575px) {
      .infosection h1 {
        font-size: 3.25vw;
        margin-top: 0;
        margin-bottom: 0; } }

.tooltip-invis {
  opacity: 0;
  padding-left: 0;
  transition: opacity 0.25s linear; }

.tooltip-vis {
  opacity: 1;
  padding-left: 0; }

/* Media Queries */
/* Media Queries */
.coupon-card, .earnings-card {
  float: left;
  margin: 10px 30px 10px 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #4a4a4a;
  width: 380px;
  box-shadow: 5px 15px 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 25px; }
  @media (max-width: 575px) {
    .coupon-card, .earnings-card {
      margin-right: auto;
      margin-left: 20px;
      width: 90%; } }

.code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px; }
  .code-container .code-text {
    margin-bottom: 10px; }

.coupon-code {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 2.6px;
  color: #007BD0; }

.earnings-card {
  clear: left; }

h3 {
  -webkit-margin-before: 10px;
          margin-block-start: 10px;
  -webkit-margin-after: 1px;
          margin-block-end: 1px;
  overflow-wrap: break-spaces;
  text-align: left;
  float: left;
  padding-left: 20px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 15px;
  color: #4a4a4a; }
  @media (max-width: 575px) {
    h3 {
      font-size: 11px; } }

h4 {
  margin-top: -55px;
  margin-bottom: -10px;
  text-align: right;
  float: right;
  padding-right: 30px;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 2.6px;
  color: #007BD0; }
  @media (max-width: 992px) {
    h4 {
      font-size: 24px; } }
  @media (max-width: 768px) {
    h4 {
      font-size: 22px; } }
  @media (max-width: 575px) {
    h4 {
      font-size: 20px; } }

hr {
  border-top: dashed 1px;
  border-bottom: none;
  clear: both;
  margin-left: 30px;
  margin-right: 30px;
  border-color: #e9e9e9;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.commission-text {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center; }

h5 {
  margin-top: 15px;
  margin-right: 15px;
  margin-left: 15px;
  text-align: center;
  background-color: #007BD0;
  padding: 2px 5px;
  max-width: 440px;
  color: #ffffff;
  font-size: 15px;
  border-radius: 25px; }
  @media (max-width: 575px) {
    h5 {
      font-size: 12px;
      margin-top: 55px; } }

h6 {
  margin-top: 20px;
  margin-bottom: 0;
  text-align: right;
  float: left;
  display: inline-block;
  padding-right: 10px;
  padding-left: 5px;
  font-size: 32px;
  font-weight: bold;
  color: #007BD0; }
  @media (max-width: 575px) {
    h6 {
      font-size: 30px; } }

p {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 10px 15px;
  margin: 0;
  color: #c1c1c1; }
  @media (max-width: 575px) {
    p {
      font-size: 10px;
      padding: 5px 10px; } }

/* Media Queries */
/* Media Queries */
table {
  table-layout: fixed;
  text-align: center;
  color: #4a4a4a;
  max-width: 460px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
  border-collapse: collapse; }
  @media (max-width: 575px) {
    table {
      width: 95%;
      margin-left: auto;
      margin-right: auto; } }

tr {
  border-bottom: 1pt solid #d9d9d9; }

th, td {
  padding-top: 10px;
  padding-bottom: 15px;
  letter-spacing: 1px; }
  @media (max-width: 575px) {
    th, td {
      padding-bottom: 5px;
      overflow-wrap: break-word; } }

th {
  height: 10px;
  font-size: 15px;
  font-weight: 600;
  width: 150px;
  color: #007BD0;
  border-bottom: 2px solid #d9d9d9; }
  @media (max-width: 575px) {
    th {
      width: 65px;
      font-size: 11px; } }

td {
  height: 20px;
  font-size: 12px;
  color: #4a4a4a;
  font-weight: 500; }
  @media (max-width: 575px) {
    td {
      font-size: 10px; } }

.date-table {
  overflow: auto; }
  @media (max-width: 575px) {
    .date-table {
      width: 100%;
      margin-bottom: 20px; } }

.monthly-card {
  clear: left;
  display: table-row;
  width: 430px;
  padding: 0 20px 20px;
  margin-bottom: 35px;
  margin-right: 30px;
  text-align: center !important; }
  @media (max-width: 575px) {
    .monthly-card {
      width: 90%;
      margin-left: 500px; } }

.no-sales {
  -webkit-margin-before: 10px;
          margin-block-start: 10px;
  -webkit-margin-after: 1px;
          margin-block-end: 1px;
  overflow-wrap: break-spaces;
  text-align: center;
  padding-left: 20px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 15px;
  color: #4a4a4a; }

@media (max-width: 575px) {
  .no-sales {
    font-size: 11px; } }

.align-right {
  text-align: right;
  border-left: 1px solid #d9d9d9; }

.container {
  margin: 80px 5px 80px 15px;
  box-shadow: 5px 15px 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 25px;
  border-spacing: 0;
  display: table; }
  @media (max-width: 575px) {
    .container {
      width: 90%;
      margin: 80px 20px; } }

caption {
  margin: 10px;
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  background-color: #007BD0;
  border-bottom: 2px solid #007BD0;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 25px; }
  @media (max-width: 575px) {
    caption {
      width: 95%;
      font-size: 17px; } }

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.error-message {
  color: red;
  margin-bottom: 16px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Media Queries */
/* Media Queries */
.sidebar {
  width: 20vw;
  align-items: center;
  background: linear-gradient(315deg, #76C3F8 0%, #007BD0 100%);
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0; }
  @media (max-width: 575px) {
    .sidebar {
      width: 100vw;
      height: 10vh; } }
  @media (min-width: 1500px) {
    .sidebar {
      width: 300px; } }
  .sidebar ul {
    list-style-type: none;
    display: block;
    width: 12vw; }
    @media (min-width: 576px) and (max-width: 767px) {
      .sidebar ul {
        margin-left: -15px; } }
    @media (min-width: 1500px) {
      .sidebar ul {
        width: 180px; } }
    @media (max-width: 575px) {
      .sidebar ul {
        display: inline-block;
        float: right;
        margin-right: 5vw;
        margin-top: 4vh;
        width: 30vw;
        white-space: nowrap; } }
    .sidebar ul li {
      float: left; }
      @media (min-width: 576px) {
        .sidebar ul li {
          margin-top: 3vh;
          display: inline; } }
      .sidebar ul li img {
        float: left;
        padding-right: 10px;
        padding-top: 2px;
        position: relative;
        width: 1.4vw; }
        @media (max-width: 575px) {
          .sidebar ul li img {
            width: 2.5vh;
            padding-right: 0; } }
        @media (min-width: 1500px) {
          .sidebar ul li img {
            width: 18px; } }

a, .menu-button {
  text-decoration: none;
  text-align: left;
  display: block;
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 1.2vw;
  float: left;
  background: none;
  border: none;
  cursor: pointer; }
  @media (min-width: 1500px) {
    a, .menu-button {
      font-size: 18px; } }
  a span, .menu-button span {
    white-space: nowrap; }
    @media (min-width: 576px) and (max-width: 767px) {
      a span, .menu-button span {
        font-size: 2vw; } }
    @media (min-width: 768px) and (max-width: 991px) {
      a span, .menu-button span {
        font-size: 1.6vw; } }
    @media (max-width: 575px) {
      a span, .menu-button span {
        display: none; } }
  @media (max-width: 575px) {
    a, .menu-button {
      display: inline-block;
      float: right; } }

.logo-img {
  width: 12vw;
  margin-top: 20px;
  float: left;
  margin-left: 15px;
  margin-bottom: 5vh; }
  @media (max-width: 575px) {
    .logo-img {
      float: left;
      width: 120px;
      align-items: center;
      margin-top: 1.5vh; } }
  @media (min-width: 576px) and (max-width: 767px) {
    .logo-img {
      width: 17vw;
      margin-left: 10px; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .logo-img {
      width: 15vw; } }
  @media (min-width: 1500px) {
    .logo-img {
      width: 180px; } }

/* Media Queries */
/* Media Queries */
.sign-in-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.sign-in-form {
  width: 400px;
  height: 400px;
  background-color: #ededed;
  box-shadow: 0 0 0 2px #d9d9d9; }

.inputs {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .inputs input {
    display: block;
    background: white;
    /*none;*/
    padding: 10px 10px 10px 5px;
    margin-top: 10px;
    border: none;
    border-radius: 0; }
  .inputs button {
    display: block;
    margin-top: 10px; }

/*.inputs {
  position: absolute;
  top: 50%;
  display: block;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
}*/
/*.sign-in-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: (-50%, -50%);
}

.sign-in-container {
  height: 100vh;
}*/

/* Media Queries */
/* Media Queries */
.admin-infosection {
  overflow-y: auto;
  overflow-x: hidden;
  width: 80vw;
  position: fixed;
  z-index: -1;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  text-align: left; }
  @media (min-width: 992px) {
    .admin-infosection {
      display: flex; } }
  @media (max-width: 575px) {
    .admin-infosection {
      display: block;
      left: 0;
      width: 100%; } }
  .admin-infosection .table-discount-code {
    color: #007BD0;
    font-size: 1.5vw;
    padding: 10px; }
    @media (max-width: 575px) {
      .admin-infosection .table-discount-code {
        font-size: 3vw; } }
    @media (min-width: 1500px) {
      .admin-infosection .table-discount-code {
        font-size: 20px; } }

/* Media Queries */
/* Media Queries */
.admin-sidebar {
  width: 20vw;
  align-items: center;
  background: linear-gradient(315deg, #76C3F8 0%, #007BD0 100%);
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0; }
  @media (max-width: 575px) {
    .admin-sidebar {
      width: 100%;
      height: 10vh; } }
  @media (min-width: 1500px) {
    .admin-sidebar {
      width: 300px; } }
  .admin-sidebar ul {
    list-style-type: none;
    display: block;
    width: 10vw; }
    @media (min-width: 576px) and (max-width: 767px) {
      .admin-sidebar ul {
        margin-left: -15px; } }
    @media (min-width: 1500px) {
      .admin-sidebar ul {
        width: 100px; } }
    @media (max-width: 575px) {
      .admin-sidebar ul {
        display: inline-block;
        float: right;
        margin-right: 5vw;
        margin-top: 4vh;
        width: 80vw;
        white-space: nowrap; } }
    .admin-sidebar ul li {
      float: left;
      width: 8vw; }
      @media (min-width: 576px) {
        .admin-sidebar ul li {
          margin-top: 3vh;
          display: inline; } }
      @media (max-width: 575px) {
        .admin-sidebar ul li {
          margin-right: 1vh;
          float: left; } }
      .admin-sidebar ul li img {
        float: left;
        padding-right: 10px;
        padding-top: 2px;
        position: relative;
        width: 1.4vw; }
        @media (max-width: 575px) {
          .admin-sidebar ul li img {
            width: 2.5vh;
            padding-right: 0; } }
        @media (min-width: 1500px) {
          .admin-sidebar ul li img {
            width: 18px; } }
    @media (max-width: 575px) {
      .admin-sidebar ul .search-results-container {
        text-align: center;
        background-color: #007BD0;
        opacity: 1;
        position: fixed;
        z-index: 10;
        top: 20vh;
        bottom: 5vh;
        left: 15vw;
        right: 15vw;
        border-radius: 25px; } }

.menu-li {
  margin-right: 0;
  width: 0; }

@media (min-width: 576px) {
  .exit-mob-search {
    display: none !important; } }

@media (max-width: 575px) {
  .exit-mob-search {
    position: fixed;
    z-index: 12;
    left: 75vw;
    top: 14vh;
    width: 7vw;
    height: 7vw;
    border: none;
    border-radius: 1vw; } }

@media (min-width: 576px) {
  .result-li {
    margin-left: -2.5vw;
    font-weight: 600; } }

.searchbar {
  border: none;
  width: 12vw;
  padding: 0.5vw;
  margin-bottom: 2.5vh; }
  @media (min-width: 1500px) {
    .searchbar {
      width: 180px; } }
  @media (max-width: 575px) {
    .searchbar {
      display: inline-block;
      position: fixed;
      z-index: 11;
      top: 21vh;
      left: 20vw;
      width: 60vw;
      height: 5vh; } }

@media (max-width: 575px) {
  .search-results-background {
    background-color: black;
    opacity: 0.5;
    position: fixed;
    top: 10vh;
    bottom: 0;
    left: 0;
    right: 0; } }

@media (max-width: 575px) {
  .results-list {
    position: fixed;
    top: 25vh;
    left: 5vw;
    bottom: 8vh;
    overflow-y: scroll; }
    .results-list li {
      height: 3.5vh;
      width: 30vw;
      margin: 5px 5px 5px 5px; }
      .results-list li a {
        font-size: 3vw;
        font-weight: 600;
        padding: 10px 10px 10px 10px;
        margin-right: 20vw; } }

a, .menu-button {
  text-decoration: none;
  padding: 0 0 1px 0;
  text-align: left;
  display: block;
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 1.2vw;
  float: left;
  background: none;
  border: none;
  cursor: pointer; }
  @media (min-width: 1500px) {
    a, .menu-button {
      font-size: 18px; } }
  a span, .menu-button span {
    white-space: nowrap; }
    @media (min-width: 576px) and (max-width: 767px) {
      a span, .menu-button span {
        font-size: 2vw; } }
    @media (min-width: 768px) and (max-width: 991px) {
      a span, .menu-button span {
        font-size: 1.6vw; } }
    @media (max-width: 575px) {
      a span, .menu-button span {
        display: none; } }
  @media (max-width: 575px) {
    a, .menu-button {
      display: inline-block;
      float: right; } }

@media (max-width: 575px) {
  .first-li {
    margin: 10px 10px 10px 10px;
    position: fixed;
    top: 2.5vh;
    right: 22vw;
    z-index: 1; } }

@media (max-width: 575px) {
  .second-li {
    margin: 10px 10px 10px 10px;
    position: fixed;
    top: 2.5vh;
    right: 13vw; } }

@media (max-width: 575px) {
  .third-li {
    margin: 10px 10px 10px 10px;
    position: fixed;
    top: 2.5vh;
    right: 2vw; } }

.menu-label {
  display: block; }
  @media (max-width: 575px) {
    .menu-label {
      display: none; } }

.logo-img {
  width: 12vw;
  margin-top: 20px;
  float: left;
  margin-left: 15px;
  margin-bottom: 1vh; }
  @media (max-width: 575px) {
    .logo-img {
      float: left;
      width: 120px;
      align-items: center;
      margin-top: 1.5vh; } }
  @media (min-width: 576px) and (max-width: 767px) {
    .logo-img {
      width: 17vw;
      margin-left: 10px; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .logo-img {
      width: 15vw; } }
  @media (min-width: 1500px) {
    .logo-img {
      width: 180px; } }

.logout-button-li {
  color: white;
  cursor: pointer; }

/* Media Queries */
.lds-roller {
  display: block;
  position: relative;
  left: 35vw;
  width: 64px;
  height: 64px; }

.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 32px 32px;
          transform-origin: 32px 32px; }

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #007BD0;
  margin: -3px 0 0 -3px; }

.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s; }

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px; }

.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s; }

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px; }

.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s; }

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px; }

.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s; }

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px; }

.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s; }

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px; }

.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s; }

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px; }

.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s; }

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px; }

.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s; }

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px; }

@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@charset "UTF-8";
.FormErrorMessage_formError__ghkFG::before {
  display: inline;
  content: "\26A0   "; }

.FormErrorMessage_formError__ghkFG {
  color: #bf1650;
  text-align: center;
  margin-top: 0;
  padding-top: 0; }

/* Media Queries */
.AddDiscountCode_addDiscountCodeForm__3W0Ph {
  width: 600px;
  padding: 20px; }
  .AddDiscountCode_addDiscountCodeForm__3W0Ph div {
    margin-bottom: 10px; }
  .AddDiscountCode_addDiscountCodeForm__3W0Ph button[type=submit] {
    margin: auto;
    height: 30px; }

.AddDiscountCode_inputContainer__3bIjy, .AddDiscountCode_discountAmount__1rGSr {
  display: flex;
  flex-direction: column; }

.AddDiscountCode_inputLabel__2bobO, .AddDiscountCode_inputContainer__3bIjy label, .AddDiscountCode_discountAmount__1rGSr label, .AddDiscountCode_hiddenLabelRightCentered__3H8PE {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .AddDiscountCode_inputLabel__2bobO span, .AddDiscountCode_inputContainer__3bIjy label span, .AddDiscountCode_discountAmount__1rGSr label span, .AddDiscountCode_hiddenLabelRightCentered__3H8PE span {
    width: 50%; }
  .AddDiscountCode_inputLabel__2bobO input, .AddDiscountCode_inputContainer__3bIjy label input, .AddDiscountCode_discountAmount__1rGSr label input, .AddDiscountCode_hiddenLabelRightCentered__3H8PE input {
    margin-bottom: 5px; }
  .AddDiscountCode_inputLabel__2bobO select, .AddDiscountCode_inputContainer__3bIjy label select, .AddDiscountCode_discountAmount__1rGSr label select, .AddDiscountCode_hiddenLabelRightCentered__3H8PE select {
    margin-bottom: 5px; }
  .AddDiscountCode_inputLabel__2bobO input, .AddDiscountCode_inputContainer__3bIjy label input, .AddDiscountCode_discountAmount__1rGSr label input, .AddDiscountCode_hiddenLabelRightCentered__3H8PE input, .AddDiscountCode_inputLabel__2bobO select, .AddDiscountCode_inputContainer__3bIjy label select, .AddDiscountCode_discountAmount__1rGSr label select, .AddDiscountCode_hiddenLabelRightCentered__3H8PE select {
    width: 50%; }

.AddDiscountCode_checkboxContainer__C2N2I input {
  margin-right: 10px; }

.AddDiscountCode_affiliatesInputs__2KyYA {
  padding: 20px;
  border: 1px solid #d1d1d1;
  border-radius: 10px; }

.AddDiscountCode_hiddenLabelRightCentered__3H8PE {
  display: flex;
  flex-direction: row; }
  .AddDiscountCode_hiddenLabelRightCentered__3H8PE span:nth-of-type(1) {
    visibility: hidden; }
  .AddDiscountCode_hiddenLabelRightCentered__3H8PE span:nth-of-type(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center; }

.AddDiscountCode_discountAmount__1rGSr label select {
  width: 100%; }

.AddDiscountCode_discountAmount__1rGSr label input {
  width: 90%; }

.AddDiscountCode_btn__2wTO4, .AddDiscountCode_btnPrimary__3NmaC, .AddDiscountCode_addDiscountCodeForm__3W0Ph button[type=submit], .AddDiscountCode_successModal__3z9_C .AddDiscountCode_successReturnBtn__3JlOb, .AddDiscountCode_btnSecondary__1lH2s, .AddDiscountCode_addDiscountCodeForm__3W0Ph button[type=button] {
  cursor: pointer;
  font-size: 0.9em;
  display: block;
  padding: 0 2em;
  border-radius: 10px;
  transition: 400ms all;
  font-weight: 550; }

.AddDiscountCode_btnPrimary__3NmaC, .AddDiscountCode_addDiscountCodeForm__3W0Ph button[type=submit], .AddDiscountCode_successModal__3z9_C .AddDiscountCode_successReturnBtn__3JlOb {
  color: #ffffff;
  background-color: #007BD0;
  border: 0; }
  .AddDiscountCode_btnPrimary__3NmaC:hover, .AddDiscountCode_addDiscountCodeForm__3W0Ph button:hover[type=submit], .AddDiscountCode_successModal__3z9_C .AddDiscountCode_successReturnBtn__3JlOb:hover {
    background-color: #005da0; }
  .AddDiscountCode_btnPrimary__3NmaC:disabled, .AddDiscountCode_addDiscountCodeForm__3W0Ph button:disabled[type=submit], .AddDiscountCode_successModal__3z9_C .AddDiscountCode_successReturnBtn__3JlOb:disabled {
    background-color: #007bd033; }

.AddDiscountCode_btnSecondary__1lH2s, .AddDiscountCode_addDiscountCodeForm__3W0Ph button[type=button] {
  color: #707070;
  border: 1px solid #d1d1d1; }
  .AddDiscountCode_btnSecondary__1lH2s:hover, .AddDiscountCode_addDiscountCodeForm__3W0Ph button:hover[type=button] {
    border-color: #007BD0;
    color: #007BD0; }
  .AddDiscountCode_btnSecondary__1lH2s:disabled, .AddDiscountCode_addDiscountCodeForm__3W0Ph button:disabled[type=button] {
    color: #d1d1d1;
    border-color: #d1d1d1; }

.AddDiscountCode_roundInput__15Ed1, .AddDiscountCode_inputLabel__2bobO input, .AddDiscountCode_inputContainer__3bIjy label input, .AddDiscountCode_discountAmount__1rGSr label input, .AddDiscountCode_hiddenLabelRightCentered__3H8PE input, .AddDiscountCode_inputLabel__2bobO select, .AddDiscountCode_inputContainer__3bIjy label select, .AddDiscountCode_discountAmount__1rGSr label select, .AddDiscountCode_hiddenLabelRightCentered__3H8PE select {
  box-sizing: border-box;
  display: inline-block;
  font-size: 0.9em;
  min-width: 9vw;
  height: 2em;
  border-radius: 10vw;
  border: 1px solid #d9d9d9;
  padding: 0 25px;
  color: black; }
  .AddDiscountCode_roundInput__15Ed1::-webkit-input-placeholder, .AddDiscountCode_inputLabel__2bobO input::-webkit-input-placeholder, .AddDiscountCode_inputContainer__3bIjy label input::-webkit-input-placeholder, .AddDiscountCode_discountAmount__1rGSr label input::-webkit-input-placeholder, .AddDiscountCode_hiddenLabelRightCentered__3H8PE input::-webkit-input-placeholder, .AddDiscountCode_inputLabel__2bobO select::-webkit-input-placeholder, .AddDiscountCode_inputContainer__3bIjy label select::-webkit-input-placeholder, .AddDiscountCode_discountAmount__1rGSr label select::-webkit-input-placeholder, .AddDiscountCode_hiddenLabelRightCentered__3H8PE select::-webkit-input-placeholder {
    color: #d9d9d9; }
  .AddDiscountCode_roundInput__15Ed1::placeholder, .AddDiscountCode_inputLabel__2bobO input::placeholder, .AddDiscountCode_inputContainer__3bIjy label input::placeholder, .AddDiscountCode_discountAmount__1rGSr label input::placeholder, .AddDiscountCode_hiddenLabelRightCentered__3H8PE input::placeholder, .AddDiscountCode_inputLabel__2bobO select::placeholder, .AddDiscountCode_inputContainer__3bIjy label select::placeholder, .AddDiscountCode_discountAmount__1rGSr label select::placeholder, .AddDiscountCode_hiddenLabelRightCentered__3H8PE select::placeholder {
    color: #d9d9d9; }

.AddDiscountCode_successModal__3z9_C {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .AddDiscountCode_successModal__3z9_C h1 {
    text-align: center; }
  .AddDiscountCode_successModal__3z9_C .AddDiscountCode_successReturnBtn__3JlOb {
    width: 100px;
    height: 30px;
    margin: auto;
    margin-top: 20px; }
  .AddDiscountCode_successModal__3z9_C div {
    display: flex;
    flex-direction: column; }
    .AddDiscountCode_successModal__3z9_C div div {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .AddDiscountCode_successModal__3z9_C div div span a {
        color: #007BD0; }

