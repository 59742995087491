@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400");
/* Media Queries */
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400");
/* Media Queries */
.admin-infosection {
  overflow-y: auto;
  overflow-x: hidden;
  width: 80vw;
  position: fixed;
  z-index: -1;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  text-align: left; }
  @media (min-width: 992px) {
    .admin-infosection {
      display: flex; } }
  @media (max-width: 575px) {
    .admin-infosection {
      display: block;
      left: 0;
      width: 100%; } }
  .admin-infosection .table-discount-code {
    color: #007BD0;
    font-size: 1.5vw;
    padding: 10px; }
    @media (max-width: 575px) {
      .admin-infosection .table-discount-code {
        font-size: 3vw; } }
    @media (min-width: 1500px) {
      .admin-infosection .table-discount-code {
        font-size: 20px; } }
