@charset "UTF-8";
.formError::before {
  display: inline;
  content: "⚠ "; }

.formError {
  color: #bf1650;
  text-align: center;
  margin-top: 0;
  padding-top: 0; }
