/* Media Queries */
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400");
/* Media Queries */
.sign-in-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.sign-in-form {
  width: 400px;
  height: 400px;
  background-color: #ededed;
  box-shadow: 0 0 0 2px #d9d9d9; }

.inputs {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }
  .inputs input {
    display: block;
    background: white;
    /*none;*/
    padding: 10px 10px 10px 5px;
    margin-top: 10px;
    border: none;
    border-radius: 0; }
  .inputs button {
    display: block;
    margin-top: 10px; }

/*.inputs {
  position: absolute;
  top: 50%;
  display: block;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
}*/
/*.sign-in-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: (-50%, -50%);
}

.sign-in-container {
  height: 100vh;
}*/
