@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,800,900|Lato:300,400");
/* Media Queries */
/* Media Queries */
table {
  table-layout: fixed;
  text-align: center;
  color: #4a4a4a;
  max-width: 460px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
  border-collapse: collapse; }
  @media (max-width: 575px) {
    table {
      width: 95%;
      margin-left: auto;
      margin-right: auto; } }

tr {
  border-bottom: 1pt solid #d9d9d9; }

th, td {
  padding-top: 10px;
  padding-bottom: 15px;
  letter-spacing: 1px; }
  @media (max-width: 575px) {
    th, td {
      padding-bottom: 5px;
      overflow-wrap: break-word; } }

th {
  height: 10px;
  font-size: 15px;
  font-weight: 600;
  width: 150px;
  color: #007BD0;
  border-bottom: 2px solid #d9d9d9; }
  @media (max-width: 575px) {
    th {
      width: 65px;
      font-size: 11px; } }

td {
  height: 20px;
  font-size: 12px;
  color: #4a4a4a;
  font-weight: 500; }
  @media (max-width: 575px) {
    td {
      font-size: 10px; } }

.date-table {
  overflow: auto; }
  @media (max-width: 575px) {
    .date-table {
      width: 100%;
      margin-bottom: 20px; } }

.monthly-card {
  clear: left;
  display: table-row;
  width: 430px;
  padding: 0 20px 20px;
  margin-bottom: 35px;
  margin-right: 30px;
  text-align: center !important; }
  @media (max-width: 575px) {
    .monthly-card {
      width: 90%;
      margin-left: 500px; } }

.no-sales {
  margin-block-start: 10px;
  margin-block-end: 1px;
  overflow-wrap: break-spaces;
  text-align: center;
  padding-left: 20px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 15px;
  color: #4a4a4a; }

@media (max-width: 575px) {
  .no-sales {
    font-size: 11px; } }

.align-right {
  text-align: right;
  border-left: 1px solid #d9d9d9; }

.container {
  margin: 80px 5px 80px 15px;
  box-shadow: 5px 15px 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 25px;
  border-spacing: 0;
  display: table; }
  @media (max-width: 575px) {
    .container {
      width: 90%;
      margin: 80px 20px; } }

caption {
  margin: 10px;
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  background-color: #007BD0;
  border-bottom: 2px solid #007BD0;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 25px; }
  @media (max-width: 575px) {
    caption {
      width: 95%;
      font-size: 17px; } }
